export enum UserRole {
  SEP_ADMIN = 'SEP_ADMIN',
  ORGANIZATION_ADMIN = 'ORG_ADMIN',
}

export interface CurrentUserUIModel {
  username: string;
  email: string;
  role: UserRole;
  organization: {
    id: string;
    name: string;
    subscriptions: Subscription[];
    tenant?: { id: string };
  } | null;
  purchaseIntent: { id: string; priceId: string; quantity: number; freeTrial: boolean } | null;
}

export interface Subscription {
  id: string;
  numberOfLicenses: number;
  payment: { paid: boolean; deadline: string | null };
  status: 'active' | 'canceled' | 'incomplete_expired' | 'incomplete' | 'past_due' | 'paused' | 'trialing' | 'unpaid';
  freeTrialing: boolean;
  createdAt: string;
  expiresAt: string;
  isBoundToLearningPlans: boolean;
}
