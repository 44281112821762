import { Injectable } from '@angular/core';
import { catchError, EMPTY, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CurrentUserDataResponseDTO } from '../models/CurrentUserDTO';
import { BaseApiRepository } from '../../../repositories/base-api-repository';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseApiRepository {
  constructor() {
    super();
  }

  getUserData(): Observable<CurrentUserDataResponseDTO> {
    const path = '/users/me';

    return this.sendGetRequest<CurrentUserDataResponseDTO>(path).pipe(
      catchError((error: HttpErrorResponse) => {
        // @TODO: add mapping to error cases and directly show toast
        console.error(error);
        return EMPTY;
      }),
    );
  }
}
