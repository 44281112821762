import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  constructor() {}

  setItem<T>(key: string, value: T): void {
    try {
      const serializedValue = JSON.stringify(value);
      sessionStorage.setItem(key, serializedValue);
    } catch {
      console.error('Error saving to sessionStorage.');
    }
  }

  getItem<T>(key: string): T | null {
    try {
      const serializedValue = sessionStorage.getItem(key);
      return serializedValue ? (JSON.parse(serializedValue) as T) : null;
    } catch {
      console.error('Error retrieving from sessionStorage.');
      return null;
    }
  }

  removeItem(key: string): void {
    try {
      sessionStorage.removeItem(key);
    } catch {
      console.error('Error removing from sessionStorage');
    }
  }

  clear(): void {
    try {
      sessionStorage.clear();
    } catch {
      console.error('Error clearing sessionStorage.');
    }
  }
}
