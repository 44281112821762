export enum SepSessionStorageKeys {
  SEP_IMPERSONATION_SESSION_DATA = 'sepImpersonationSessionData',
}

export interface SepImpersonationSessionData {
  organization: {
    id: string;
    name: string;
    subscriptions: Array<{
      id: string;
      numberOfLicenses: number;
      payment: { paid: boolean; deadline: string | null };
      status:
        | 'active'
        | 'canceled'
        | 'incomplete_expired'
        | 'incomplete'
        | 'past_due'
        | 'paused'
        | 'trialing'
        | 'unpaid';
      freeTrialing: boolean;
      createdAt: string;
      expiresAt: string;
      isBoundToLearningPlans: boolean;
    }>;
    tenant: { id: string };
  };
}
