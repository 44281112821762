import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult, IdTokenClaims, InteractionRequiredAuthError } from '@azure/msal-browser';
import { environment } from '../../../environments/environment';

interface CustomIdTokenClaims extends IdTokenClaims {
  groups?: string[];
  oid: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private readonly msalService: MsalService) {
    this.msalService.initialize();
  }

  private hasSepAdminIdentifier(authenticationResult: AuthenticationResult): boolean {
    const idTokenClaims = authenticationResult.idTokenClaims as CustomIdTokenClaims;
    const groups: string[] | undefined = idTokenClaims.groups;
    return groups?.includes(environment.msalConfig.sepAdminIdTokenClaim) ?? false;
  }

  async getMsalAuthenticationResult(): Promise<AuthenticationResult | null> {
    const [account] = this.msalService.instance.getAllAccounts();
    try {
      return await this.msalService.instance.acquireTokenSilent({
        account: account,
        scopes: ['User.Read'],
      });
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        console.error('Interaction required to acquire token:', error.message);
      }
      return null;
    }
  }

  extractUserInfo(result: AuthenticationResult | null): { oid: string | null; username: string | undefined } {
    if (result) {
      const oid = (result.idTokenClaims as IdTokenClaims | null)?.oid ?? null;
      const { username } = result.account;
      return { oid, username };
    }
    return { oid: null, username: undefined };
  }

  async isSepAdmin(): Promise<boolean> {
    const authenticationResult = await this.getMsalAuthenticationResult();
    if (!authenticationResult) {
      return false;
    }
    return this.hasSepAdminIdentifier(authenticationResult);
  }

  logout() {
    this.msalService.logoutRedirect();
  }
}
