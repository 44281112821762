import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { environment } from '../../../../environments/environment';
import { AnalyticsEvents } from './AnalyticsEvents';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private consentGiven = false;
  private isIdentified = false;

  init(): void {
    if (this.allowAnalytics()) {
      mixpanel.init(environment.analytics.mixpanelProjectToken, {
        debug: !environment.production,
      });
    }
  }

  identify(userToken: string): void {
    if (this.allowAnalytics()) {
      mixpanel.identify(userToken);
      this.isIdentified = true;
    }
  }

  reset(): void {
    if (this.allowAnalytics()) {
      mixpanel.reset();
    }
  }

  track<E extends AnalyticsEvents['eventName']>(
    eventName: E,
    props?: Extract<AnalyticsEvents, { eventName: E }>['props'],
  ) {
    if (this.allowAnalytics()) {
      mixpanel.track(eventName, props);
    }
  }

  setConsent(consentGiven: boolean): void {
    this.consentGiven = consentGiven;
    this.updateAnalyticsState();
  }

  get isUserIdentified() {
    return this.isIdentified;
  }

  private updateAnalyticsState(): void {
    if (!this.consentGiven) {
      this.reset();
      return;
    }
    this.init();
  }

  private allowAnalytics(): boolean {
    return environment.analytics.enableAnalytics && this.consentGiven;
  }
}
