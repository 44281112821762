import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { environment } from '../../../environments/environment';

export abstract class BaseApiRepository {
  protected readonly http = inject(HttpClient);
  protected readonly baseUrl = environment.backendConfig.baseUrl;

  protected sendGetRequest<T>(path: string, queryParams?: HttpParams, headers?: HttpHeaders): Observable<T> {
    return this.http.get<T>(this.baseUrl + path, { params: queryParams, headers });
  }

  protected sendPostRequest<P, T>(
    path: string,
    payload?: P,
    queryParams?: HttpParams,
    headers?: HttpHeaders,
  ): Observable<T> {
    return this.http.post<T>(this.baseUrl + path, payload, { params: queryParams, headers });
  }

  protected sendPatchRequest<P, T>(
    path: string,
    payload?: P,
    queryParams?: HttpParams,
    headers?: HttpHeaders,
  ): Observable<T> {
    return this.http.patch<T>(this.baseUrl + path, payload, { params: queryParams, headers });
  }

  protected sendGetBlobRequest(path: string, queryParams?: HttpParams, headers?: HttpHeaders): Observable<Blob> {
    return this.http.get(this.baseUrl + path, { params: queryParams, headers, responseType: 'blob' });
  }
}
